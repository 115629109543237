import PageHeader from "../components/pageHeader"
import bbq from "../img/bbq.jpg"
import { Col, Container, Row } from "reactstrap"
import image24 from "../img/Chiva.jpg"
import { Link } from "gatsby"
import Layout from "../components/layout"
import React from "react"

const Page404 = () =>
  <Layout>
    <PageHeader backgroundImage={bbq} title="Page not found"/>
    <section className="section section_info section_info_opposite">
      <Container>
        <Row>
          <Col>
            <h2 className="section__heading text-center">
              Opps, page not found
            </h2>
          </Col>
        </Row>
        <Row className="align-items-justify">
          <Col md={6} className="order-md-3">
            <div className="section_info__img">
              <img src={image24} alt="..."/>
            </div>
          </Col>
          <Col md={1} className="order-md-2"/>
          <Col md={5} className="order-md-1">
            <div className="section_info__body">
              <p className="lead text-heading">
                We are here to help
              </p>
              <p>
                Send us a message by email or using the form and we will get back to you as soon as possible. Also feel free to give us a call and one of our team members will help you.
              </p>
              <br/>
              <p>
                <Link to="/" className="btn btn-primary">
                  Contact Us
                </Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  </Layout>


export default Page404
